import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CInlineDefinition,
  CHeroSlider,
  CDefinition,
  CNewsList,
  CBtnList,
  CMultiSelectBtn,
  CSingleSelect,
  CRestaurantPlan,
  CFooterRestrantSearch,
} from '../../../components/_index';
import restaurantPlanGet from '../../../utils/restaurantPlan-get';

// markup
const SubPage = ({ data, location }: { data: any; location: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const { purpose, pickup } = queryString.parse(location.search);
  const inipurpose = purpose
    ? Array.isArray(purpose)
      ? purpose
      : [purpose]
    : new Array();
  const inipickup = pickup ? (Array.isArray(pickup) ? '' : pickup) : '';

  const [checked, setChecked] = useState(inipurpose);
  const [selected, setSelected] = useState(inipickup);
  const [moreflag, setMoreflag] = useState(true);
  const maxcount = 15;
  const onMoreClick = () => {
    setMoreflag(false);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const query = checked.map((item) => {
      return {
        name: 'purpose',
        value: item,
      };
    });
    if (selected) {
      query.push({
        name: 'pickup',
        value: selected,
      });
    }
    const replacequery =
      query.length > 0
        ? '?' + query.map((item) => `${item.name}=${item.value}`).join('&')
        : url.pathname;
    history.replaceState({}, '', replacequery);
  });

  const filterDataAll: Array<any> = restaurantPlanGet().filter((item: any) => {
    let checkedflag = false;
    let selectedflag = false;
    if (checked.length < 1) {
      checkedflag = true;
    } else {
      checkedflag = item.node.purpose.some((el: string) =>
        checked.includes(el)
      );
    }
    if (!selected) {
      selectedflag = true;
    } else {
      selectedflag = item.node.pickup.includes(selected);
    }
    return checkedflag && selectedflag;
  });
  const filterData = filterDataAll.slice(0, moreflag ? maxcount : undefined);
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'PLAN',
            sub: <>レストランプラン</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/plan/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/plan/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <section className="l_sect u_pt100">
        <LWrap>
          <CSectTitle
            exClass="u_tal u_mbSmall"
            title={{
              en: '',
              ja: 'プランの絞り込み',
            }}
          />
          <p className="u_fs16 u_fwb">目的から選ぶ</p>
          <CMultiSelectBtn
            checked={checked}
            setChecked={setChecked}
            selectList={[
              'カップル',
              'ファミリー',
              'グループ',
              'ビジネス・接待',
            ]}
          />
          <p className="u_fs16 u_fwb u_mt40">Pick Up</p>
          <div className="u_mt20 u_mbMedium">
            <CSingleSelect
              selected={selected}
              setSelected={setSelected}
              selectList={[
                '季節限定・おすすめ',
                'アニバーサリー',
                'パーティー',
                '個室',
                'ランチ',
                'ディナー',
              ]}
            />
          </div>
          <CRestaurantPlan data={filterData} />
          {moreflag && filterDataAll.length > maxcount && (
            <ul className="c_btnList c_btnList__center">
              <li className="item">
                <button
                  type="button"
                  className="c_btn c_btn__borderBlack"
                  onClick={onMoreClick}
                >
                  <span className="c_btn_label">もっと見る</span>
                  <i className="icon-plus"></i>
                </button>
              </li>
            </ul>
          )}
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'レストラン&バー',
              path: '/restaurants/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <CFooterRestrantSearch formId="footer" />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
